import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="footerWrapper">
      <footer className="customFooter">
        <Link to="/about">&copy; 2024 All Rights Reserved</Link>
      </footer>
    </div>
  );
};

export default Footer;
