// src/services/socket.ts
import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";

let socket: Socket | null = null;

const useSocket = () => {
  const [socketInstance, setSocketInstance] = useState<Socket | null>(null);

  /// modify this to use for prod as well below
  // useEffect(() => {
  //   if (!socket) {
  //     socket = io("http://localhost", {
  //       path: "/socket.io",
  //       transports: ["websocket", "polling"],
      // });
      useEffect(() => {
        if (!socket) {
          const socketUrl = process.env.NODE_ENV === 'production' 
            ? 'wss://quantumcloudtechnologies.com'
            : 'http://localhost';
    
          socket = io(socketUrl, {
            path: '/socket.io',
            transports: ['websocket', 'polling'],
          });

      socket.on("connect", () => {
        console.log("Connected to WebSocket server");
      });

      socket.on("disconnect", () => {
        console.log("Disconnected from WebSocket server");
      });
    }

    setSocketInstance(socket);

    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("disconnect");
      }
    };
  }, []);

  return socketInstance;
};

export default useSocket;


////////////////////////////////////////// this does not allow socket.io fix above
// const useSocket = () => {
//   const [socketInstance, setSocketInstance] = useState<Socket | null>(null);

//   useEffect(() => {
//     if (!socket) {
//       const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";
//       const socketUrl = apiUrl.replace("/api", "");

//       socket = io(socketUrl, {
//         transports: ["websocket", "polling"],
//       });

//       socket.on("connect", () => {
//         console.log("Connected to WebSocket server");
//       });

//       socket.on("disconnect", () => {
//         console.log("Disconnected from WebSocket server");
//       });
//     }

//     setSocketInstance(socket);

//     return () => {
//       if (socket) {
//         socket.off("connect");
//         socket.off("disconnect");
//       }
//     };
//   }, []);

//   return socketInstance;
// };

 