// Refactor to use lazy loading for better performance
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import useSocket from "./services/socket";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import "./css/main.css";

// Lazy load components that are not needed right away
const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Users = React.lazy(() => import("./pages/Users"));
const Roles = React.lazy(() => import("./pages/Roles"));
const FileManagement = React.lazy(() => import("./components/FileManagement"));
const ChatElement = React.lazy(() => import("./components/Chat/ChatElement"));
const ContactUs = React.lazy(() => import("./components/ContactUs/ContactUs"));

function App() {
  const socket = useSocket();

  return (
    <AuthProvider>
      <div className="App">
        <Navigation />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/files" element={<FileManagement />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/users/*" element={<Users />} />
            <Route path="/roles/*" element={<Roles />} />
            <Route path="/chat" element={<ChatElement />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;

 