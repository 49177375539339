import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import logo from '../img/logo01.png';  // Adjust the path to match the actual file location
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import './Navigation.css'; // Assuming you have a CSS file for styling

const Navigation: React.FC = () => {
  const { user, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navigation">
      <nav className="navigation-nav">
        <Link to="/" className="logo">
          <img src={logo} alt="Logo" />  {/* Use the imported image */}
        </Link>

        <div className="ActionsBtn">
          {menuOpen ? (
            <AiOutlineClose className="menu-icon" onClick={toggleMenu} /> // Close icon
          ) : (
            <AiOutlineMenu className="menu-icon" onClick={toggleMenu} /> // Menu icon
          )}
        </div>

        {menuOpen && (
          <div className="fullscreen-content">
            <div className="content">
              <ul>
                <li>
                  <Link to="/" onClick={toggleMenu}>Home</Link>
                </li>
                {user ? (
                  <>
                    <li>
                      <Link to="/profile" onClick={toggleMenu}>Profile</Link>
                    </li>
                    <li>
                      <Link to="/chat" onClick={toggleMenu}>Chat</Link>
                    </li>
                    <li>
                      <Link to="/files" onClick={toggleMenu}>Files</Link>
                    </li>
                    {user.role === "admin" && (
                      <>
                        <li>
                          <Link to="/users" onClick={toggleMenu}>Users</Link>
                        </li>
                        <li>
                          <Link to="/roles" onClick={toggleMenu}>Roles</Link>
                        </li>
                      </>
                    )}
                    <li>
                      <button onClick={() => { logout(); toggleMenu(); }} className="navigation-submit">
                        SignOut
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/register" onClick={toggleMenu}>Register</Link>
                    </li>
                    <li>
                      <Link to="/login" onClick={toggleMenu}>Sign In</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        )}

        <ul className="desktop-menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          {user ? (
            <>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/chat">Chat</Link>
              </li>
              <li>
                <Link to="/files">Files</Link>
              </li>
              {user.role === "admin" && (
                <>
                  <li>
                    <Link to="/users">Users</Link>
                  </li>
                  <li>
                    <Link to="/roles">Roles</Link>
                  </li>
                </>
              )}
              <li>
                <button onClick={logout} className="navigation-submit">
                  SignOut
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/register">Register</Link>
              </li>
              <li>
                <Link to="/login">Sign In</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
